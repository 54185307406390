import React, { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { PLACEHOLDER } from "../../helpers/constant";
import RoundImage from "../Image/RoundImage";
import styles from "./recentchat.module.css";
import BackButton from "../BackButton/BackButton";
import { useSelector } from "react-redux";
import { fromNow } from "../../helpers/dates";
import moment from "moment";

const RecentChat = React.memo((props) => {
    const { chats, onClick } = props;
    const auth_user = useSelector((store) => store.user.user);
    const [search, setSearch] = useState("");

    const selectUser = (chat) => {
        onClick(chat.target_id == auth_user.id ? chat.source_id : chat.target_id);

    };

    const renderUsersList = () => {
        return chats?.map((chat, i) => {
            if (chat.target_id != auth_user.id && !chat.target_name.toLowerCase().includes(search.toLowerCase())) {
                return null;
            }

            if (chat.source_id != auth_user.id && !chat.source_name.toLowerCase().includes(search.toLowerCase())) {
                return null;
            }

            return (
                <li className={`${styles["active"]} active p-2 d-flex align-items-center`} key={i} onClick={() => selectUser(chat)}>
                    <div className="fig float-left" style={{ width: "15%" }}>
                        <RoundImage className={`mr-2`} size={"30px"} src={(chat.target_id == auth_user.id ? chat.source_pp : chat.target_pp) || PLACEHOLDER} />
                    </div>
                    <div className="float-left" style={{ width: "85%" }}>
                        {auth_user.id == chat?.target_id ? chat?.source_name : chat?.target_name}{" "}
                        {!!chat.seen == false && auth_user.id !== chat.source_id && (
                            <span
                                style={{
                                    background: "#ff7373 none repeat scroll 0 0",
                                    color: "#fff",
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "100%",
                                    position: "absolute",
                                    margin: "5px",
                                }}
                            ></span>
                        )}
                        <span className={`${styles["invit text-muted"]} invit text-muted float-right`}>{moment.utc(chat.created_at).local().format("HH:mm A")}</span>
                    </div>
                </li>
            );
        });
    };

    // const changeSearch = (e) => {

    // }

    return (
        <aside className={`${styles["sidebar"]} ${styles["recent-chat-sidebar"]} sidebar static left`}>
            <div className={`${styles["widget"]} widget`}>
                <BackButton />
                <hr />
                <h4 className={`${styles["widget-title"]} widget-title`}>
                    Chat
                    {/* <a href="#" className={`${styles["see-all"]} ${styles["send-mesg"]} see-all send-mesg`} title="" data-toggle="tooltip" data-original-title="Create new message">
                        <i className={`${styles["icofont-edit"]} icofont-edit`}></i>
                    </a> */}
                </h4>
                <input className={`${styles["search"]} search`} type="text" name="" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                {chats?.length > 0 && <ul className={`${styles["invitepage"]} mt-3 invitepage`}>{renderUsersList()}</ul>}
            </div>
        </aside>
    );
});

export default RecentChat;
