import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";
// import styles from "./chatroom.module.css";
import { messages as messageApi } from "../../api/messages";
import { useDispatch, useSelector } from "react-redux";
import { users } from "../../api/users";
import ScrollList from "../../component/ScrollList/ScrollList";
import _ from "lodash";
import { getCalendarDate } from "../../helpers/dates";
import moment from "moment";
// import styles from "../../component/ChatBox/chatbox.module.css";
import styles from "./singlechat.module.css";
import { getSocket } from "../../core/socket";
import Button from "../../component/Button/Button";
import { toast } from "react-toastify";

const SingleChat = (props) => {
    const { user_id } = props;
    const [messages, setMessages] = useState([]);
    const dispatch = useDispatch();
    const auth_user = useSelector((store) => store.user.user);
    // const [user, setUser] = useState();
    const [user, setUser] = useState(props.user);
    const [message, setMessage] = useState("");
    const listRef = useRef();
    const [historyPagination, setHistoryPagination] = useState({});
    const active_chat = useSelector((store) => store.messages.activeChats?.[props.userIdName] || []);
    const [activeChatLength, setActiveChatLength] = useState(active_chat?.length);

    useEffect(() => {
        if (active_chat[active_chat.length - 1]) {
            if (messages[messages.length - 1]?.id !== active_chat[active_chat.length - 1].id) {
                setMessages([active_chat[0]].concat(messages));
            }
        }
    }, [active_chat]);

    const sendMessage = () => {
        dispatch(
            messageApi({
                data: {
                    target_id: user_id,
                    source_id: auth_user.id,
                    message,
                },
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    getSocket().emit("messaging", response.data);
                    setMessages((p) => [response.data].concat(p).sort((a, b) => moment(b.created_at).diff(moment(a.created_at))));
                    setMessage("");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const transformChatByGroup = useMemo(() => {
        return _.chain(messages)
            .groupBy((m) => getCalendarDate(m.created_at))
            .reduce((result, value, key) => {
                result[key] = value;
                return result;
            }, {})
            .value();
    }, [messages]);

    const renderItems = useCallback(
        ({ item }) => {
            return (
                <li key={item.id} className={`${parseInt(item?.target_user?.id) == parseInt(auth_user.id) ? styles["you"] : styles["me"]}`} style={{ listStyle: "none" }}>
                    <div className={`${styles["chat-thumb"]} m-1`}>
                        <RoundImage src={item?.source_user?.profile_pic || PLACEHOLDER} alt="" size="30px" />
                    </div>
                    <div className={`${styles["notification-event"]}`}>
                        <span className={`${styles["chat-message-item"]}`}>{item.message}</span>
                        <span className={`${styles["notification-date"]}`}>
                            <time dateTime={item.created_at} className="entry-date updated">
                                {moment(item.created_at).format("hh:mm a")}
                            </time>
                        </span>
                    </div>
                </li>
            );
        },
        [user_id],
    );

    const renderChatItem = useCallback(
        (key) => {
            const chatgroup = transformChatByGroup[key];
            return (
                <React.Fragment key={key}>
                    {chatgroup?.map((item) => renderItems({ item }))}
                    <li>
                        <p>{key}</p>
                    </li>
                </React.Fragment>
            );
        },
        [renderItems, transformChatByGroup],
    );

    const renderOnEmptyChat = useMemo(() => {
        return (
            <div className={`${styles["chat-empty"]}`}>
                <p>Say Hi</p>
            </div>
        );
    }, []);

    const getUserHistoryMessages = useCallback(
        async (new_chat = false) => {
            listRef.current?.setLoading(true);
            try {
                const params = {
                    param_path: user_id,
                };
                if (!!new_chat.type && historyPagination.current_page < historyPagination.last_page) {
                    params.params = {
                        load: messages[messages.length - 1]?.id,
                    };
                }

                params.params = {
                    ...params.params,
                    per_page: 15,
                };
                const response = await dispatch(messageApi(params));

                setMessages((p) => p.concat(response.data));
                setHistoryPagination(response.pagination_data);
                listRef.current?.setLoading(false);
            } catch (err) {
                console.log(err);
            }
        },
        [dispatch, user_id, historyPagination, messages],
    );

    useEffect(() => {
        setMessages([]);
        setHistoryPagination({});
        getUserHistoryMessages(true);
    }, [user_id]);

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            sendMessage();
        }
    };
    const rejectMsgRequest = () => {
        dispatch(
            messageApi({
                param_path: `${user?.id}/decline`,
                method: "POST",
            }),
        )
            .then((response) => {
                setUser({
                    ...user,
                    chat_status: "declined",
                });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const approveMsgRequest = () => {
        dispatch(
            messageApi({
                param_path: `${user?.id}/approve`,
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    // toast.success("The message request has been approved");
                    setUser({
                        ...user,
                        chat_status: "approved",
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <div className={`${styles["chat-content"]} ${styles["chat-card-room"]} h-100`}>
            {/* <div className={`${styles["date"]} date`}>Wednesday 25, March</div> */}
            {user?.chat_status == "requested" && (
                <>
                    {messages?.length && (
                        <div>
                            {messages[0].target_user?.id == auth_user?.id ? (
                                <>
                                    <div className="d-flex">
                                        <ul className="list-unstyled">

                                            <li className={`${styles["you"]} d-flex`}>
                                                <div className={`${styles["chat-thumb"]} m-1`}>
                                                    <RoundImage src={messages[0]?.source_user?.profile_pic || PLACEHOLDER} alt="" size="30px" />
                                                </div>
                                                <div className={`${styles["notification-event"]} w-100`}>
                                                    <span className={`${styles["chat-message-item"]} d-block`}>{messages[0].message}</span>
                                                    <span className={`${styles["notification-date"]} text-right`}>
                                                        <time dateTime={messages[0].created_at} className="entry-date updated">
                                                            {moment(messages[0].created_at).format("hh:mm a")}
                                                        </time>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr className="my-1" />
                                    <div className="text-center">
                                        <p className="mb-0">This person wants to send you message.</p>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button type="button" className={`btn btn-sm mr-2 btn-danger`} onClick={rejectMsgRequest}>
                                            Decline
                                        </Button>
                                        <Button type="button" className={`btn btn-sm btn-success`} onClick={approveMsgRequest}>
                                            Approve
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="text-center">
                                        <p className="mb-0">Your request for messaging has not been approved yet.</p>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
            {user?.chat_status == "approved" && (
                <>
                    <ul
                        style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                            flex: "1",
                        }}
                    >
                        <ScrollList
                            data={Object.keys(transformChatByGroup)}
                            renderItem={renderChatItem}
                            isReverse
                            onEndReach={getUserHistoryMessages}
                            renderOnEmptyList={renderOnEmptyChat}
                            threshold={100}
                            hasMore={historyPagination.current_page < historyPagination.last_page}
                            pagination={historyPagination}
                        />
                    </ul>
                    {user.status != "deactivate" && user.status != "deleted" && user.status != "blocked" ? (
                        <form className={`${styles["text-box"]} mt-3`} onSubmit={sendMessage}>
                            <textarea
                                className="pr-4"
                                onKeyDown={onKeyDown}
                                name="message"
                                placeholder="Write Mesage..."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                maxLength="200"
                            />
                            <button className="d-flex align-items-center btn-info btn-sm" type="button" onClick={sendMessage}>
                                <i className="icofont-paper-plane" style={{ fontSize: "18px" }} />
                            </button>
                        </form>
                    ) : (
                        <div className="deacitvated-user text-center bg-light">
                            <p className="mb-0 p-2" title="This user is either deactivated or blocked">
                                You cannot send messages to this user!
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SingleChat;
