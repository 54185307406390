import React, { useState, useEffect } from "react";
import ThemeLayout from "../../layout/themeLayout";
import RecentChat from "../../component/Chat/RecentChat";
import styles from "./chatroom.module.css";
import SingleChat from "./SingleChat";
import { useDispatch, useSelector } from "react-redux";
import { users, users as usersApi } from "../../api/users";
import RoundImage from "../../component/Image/RoundImage";
import { Link } from "react-router-dom";
import { PLACEHOLDER } from "../../helpers/constant";
import Button from "../../component/Button/Button";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";

const ChatRoom = React.memo((props) => {
    const auth_user = useSelector((store) => store.user.user);
    const dispatch = useDispatch();
    const [chats, setChats] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [user, setUser] = useState({});
    const latestMessage = useSelector((store) => store.messages.latestMessage);
    const [top, setTop] = useState();

    useEffect(() => {
        if (!auth_user?.id) return;
        dispatch(
            usersApi({
                param_path: "recent_chats",
                params: {
                    per_page: 20,
                },
            })
        )
            .then((response) => {
                if (response.success) {
                    setChats(response.data);
                    if (response.data.length > 0 && !selectedUser) {
                        let t_user = response?.data[0]?.target_id;
                        if (t_user == auth_user?.id) {
                            selectUser(response.data[0]?.source_id);
                            setTop(response.data[0]?.source_id);
                        } else {
                            selectUser(response.data[0]?.target_id);
                            setTop(response.data[0]?.target_id);
                        }
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [auth_user, dispatch]);

    useEffect(() => {
        if (latestMessage?.source_user?.id !== top) {
            dispatch(
                usersApi({
                    param_path: "recent_chats",
                    params: {
                        per_page: 20,
                    },
                })
            )
                .then((response) => {
                    if (response.success) {
                        setChats(response.data);
                        setTopUser(response.data);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            if (selectedUser) {
                setChats(
                    chats.map((chat) => {
                        if (
                            (chat.source_id ===
                                latestMessage?.source_user?.id &&
                                chat.source_id !== selectedUser) ||
                            (chat.target_id ===
                                latestMessage?.source_user?.id &&
                                chat.source_id !== selectedUser)
                        ) {
                            chat.seen = 0;
                        }
                        return chat;
                    })
                );
            }
        }
    }, [latestMessage, top]);

    const setTopUser = (data) => {
        if (data.length > 0) {
            let t_user = data[0]?.target_id;
            if (t_user == auth_user?.id) {
                setTop(data[0]?.source_id);
            } else {
                setTop(data[0]?.target_id);
            }
        }
    };

    const selectUser = (id) => {
        setSelectedUser(id);

        const new_chats = chats.map((chat) => {
            if (chat?.target_id == id || chat?.source_id == id) {
                chat.seen = true;
            }
            return chat;
        });
        setChats(new_chats);
    };

    useEffect(() => {
        if (!selectedUser) return;
        dispatch(
            users({
                param_path: `${selectedUser}`,
            })
        )
            .then((response) => {
                if (response.success) {
                    setUser(response.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [dispatch, selectedUser]);

    const [showConfirm, setShowConfirm] = useState(false);

    const blockUser = () => {
        dispatch(
            usersApi({
                param_path: `block/${selectedUser}`,
            })
        )
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);
                    setChats(
                        chats.filter(
                            (c) =>
                                c.source_id !== selectedUser ||
                                c.target_id !== selectedUser
                        )
                    );
                    setShowConfirm(false);
                } else {
                    toast.error(response.message);
                    setShowConfirm(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setShowConfirm(false);
    };

    return (
        <ThemeLayout>
            <ConfirmModal
                showModal={showConfirm}
                title="Confirm"
                message={`Are you sure you want to block this user?`}
                onConfirm={() => blockUser(selectedUser)}
                onClose={() => setShowConfirm(false)}
            />

            <section>
                <div className={`${styles["gap"]} gap`}>
                    <div className={`${styles["container"]} container`}>
                        <div className={`${styles["row"]} row`}>
                            <div className={`${styles["col-lg-12"]} col-lg-12`}>
                                <div
                                    id={`page-contents`}
                                    className={`${styles["row"]} ${styles["page-contents"]} ${styles["merged20"]} row merged20`}
                                >
                                    <div
                                        className={`${styles["col-lg-4"]} col-lg-4`}
                                    >
                                        <RecentChat
                                            chats={chats}
                                            onClick={selectUser}
                                        />
                                    </div>
                                    <div
                                        className={`${styles["col-lg-8"]} col-lg-8`}
                                    >
                                        <div
                                            className={` ${styles["main-wraper"]} main-wraper`}
                                        >
                                            <div className="card-header p-3 mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <RoundImage
                                                        className={`mr-2`}
                                                        src={`${
                                                            user?.profile_pic ||
                                                            PLACEHOLDER
                                                        }`}
                                                        size={`40px`}
                                                    />
                                                    <div className="user-info">
                                                        <div
                                                            className="u-name"
                                                            style={{
                                                                lineHeight: "1",
                                                            }}
                                                        >
                                                            <Link
                                                                className="d-block"
                                                                to={`/profile/${user?.id}`}
                                                            >
                                                                {user?.name}
                                                            </Link>
                                                            <small className="username">
                                                                @
                                                                {user?.username}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        type="button"
                                                        onClick={() => {
                                                            setShowConfirm(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Block{" "}
                                                    </Button>
                                                </div>
                                                {/* <h5>{user?.name}</h5> */}
                                            </div>

                                            <div
                                                className={`${styles["message-box"]} message-box`}
                                            >
                                                <div
                                                    className={`${styles["message-content"]} message-content h-100`}
                                                >
                                                    {user?.id && (
                                                        <SingleChat
                                                            user={user}
                                                            user_id={user?.id}
                                                            userIdName={`${user?.id}|${user?.name}`}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
});

export default ChatRoom;
